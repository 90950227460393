import React from "react"
import "../style/TrustBox.css"

const TrustBox = ({ type, country, GTMLabel, onClick, data_template_id }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  const refTest = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div ref={refTest}>
      {type === "mini" && (
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget" // Renamed this to className.
          // [ long list of data attributes...]
          data-locale={country}
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="5fb7fd5f3a4430000138fa6d"
          data-style-height="150px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a
            href="https://www.trustpilot.com/review/fanhome.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
      {type === "horizontal" && (
        <div
          ref={ref}
          class="trustpilot-widget"
          data-locale={country}
          data-template-id="5419b637fa0340045cd0c936"
          data-businessunit-id="5fb7fd5f3a4430000138fa6d"
          data-style-height="20px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://www.trustpilot.com/review/fanhome.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
      {type === "slider" && (
        <div
          ref={ref}
          class="trustpilot-widget slider-trustpilot"
          data-locale={country}
          data-template-id={data_template_id?.field_value}
          data-businessunit-id="5fb7fd5f3a4430000138fa6d"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-tags="SelectedReview"
          data-review-languages="en"
        >
          <a
            href="Fanhome is rated 'Excellent' with 4.3 / 5 on Trustpilot"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
    </div>
  )
}
export default TrustBox
