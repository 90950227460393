import * as React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"

import MuiSelect from "@material-ui/core/Select"
import Icon from "./Icon"

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {},
  selectRoot: {
    paddingTop: "12px!important",
  },
  filled: {
    borderBottom: `2px solid ${theme.customPalette.tangerine}`,
  },
  icon: {
    width: 24,
    height: 24,
  },
})

const Select = React.forwardRef(function Select(props, ref) {
  const { classes, className, value, ...other } = props

  return (
    <MuiSelect
      fullWidth
      variant="filled"
      IconComponent={({ className }) => (
        <Icon className={clsx(classes.icon, className)} name="select" />
      )}
      margin="normal"
      value={value}
      className={clsx(classes.root, className, {
        [classes.filled]: !!value,
      })}
      ref={ref}
      classes={{
        root: classes.selectRoot,
      }}
      {...other}
    />
  )
})

Select.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
}

export default withStyles(styles, { name: "FHSelect" })(Select)
