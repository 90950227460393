import React, { useMemo, useEffect } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

import Image from "./Image"
import { useTranslation } from "react-i18next"

import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles(theme => ({
  logo: {
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    textIndent: "-9999px",
    width: "100%",
    maxWidth: "184px",
    height: 56,
    [theme.breakpoints.only("xs")]: {
      maxWidth: "112px",
      height: 35,
    },
  },
  description: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "0",
    width: "0",
  },
}))

const Logo = ({ className, dark, id, basePath, templateLabel }) => {
  const data = useStaticQuery(graphql`
    query {
      mobileImages: allFile(
        filter: { relativePath: { regex: "/[_0-9A-Za-z/]*.png/i" } }
      ) {
        nodes {
          name
          relativePath
          publicURL
        }
      }
      tabletImages: allFile(
        filter: { relativePath: { regex: "/[_0-9A-Za-z/]*@2x.png/i" } }
      ) {
        nodes {
          name
          relativePath
          publicURL
        }
      }
      desktopImages: allFile(
        filter: { relativePath: { regex: "/[_0-9A-Za-z/]*@3x.png/i" } }
      ) {
        nodes {
          name
          relativePath
          publicURL
        }
      }
    }
  `)
  const classes = useStyles()

  const { t } = useTranslation()

  const nameLogo = dark ? "logo" : "logo_white"
  const regexName = new RegExp(
    `^${nameLogo}$|^${nameLogo}@2x|^${nameLogo}@3x`,
    "i"
  )

  const match = useMemo(() => {
    // Move dependancy func inside to avoid exhaustive-deps warning
    const filter = img => img.name.match(regexName)
    return {
      img3x: data.desktopImages.nodes.find(filter).publicURL,
    }
  }, [data, regexName])

  const handleClick = () => {
    const parts = window.location.pathname.split("/")
    if (parts.length > 1) {
      window.location.href = `/${parts[1]}/`
    }
  }

  return (
    <a
      id={id}
      onClick={handleClick}
      className={clsx(className, classes.logo)}
      style={{
        backgroundImage: `url("${match.img3x}")`,
      }}
      title={t("logo.title")}
    >
      <p>{t("logo.title")}</p>
      <p className={classes.description}>{t("logo.description")}</p>
    </a>
  )
}

Logo.propTypes = {
  /**
   * The variat of the component.
   */
  dark: PropTypes.bool,
}
Logo.defaultProps = {}

export default Logo
