import React from "react"

const LinkSchema = {
  href: "http://www.example.com",
  hrefLang: "it",
  rel: "no-follow",
  target: "_self",
  title: "link title",
}

const Link = ({
  href,
  hrefLang,
  rel,
  target,
  title,
  className,
  children,
  onClick,
}) => {
  return (
    <a
      className={className}
      href={href}
      hrefLang={hrefLang}
      rel={rel}
      target={target}
      title={title}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default Link
export { LinkSchema }
