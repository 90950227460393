import { window } from "browser-monads";

export const GTMEvent = (reference, action, label) => {
  window.dataLayer = window.dataLayer || [];

  let categoryValue = "", ecommerceValue;

  if (typeof reference === "string") {
    categoryValue = reference;
  } else {
    if (reference?.tracking?.dataLayer) {
      (reference?.tracking?.dataLayer).forEach((item) => {
        if (item?.ecommerce) {
          categoryValue = item?.ecommerce?.detail?.products[0]?.dimension8 || reference?.template_label || ""
          ecommerceValue = item?.ecommerce

          if (action === "cta sticky")
            label = item?.ecommerce?.detail?.products[0]?.name || label || ""
        }

        if (!categoryValue)
          categoryValue = item?.pageFamily || reference?.template_label || ""
      })
    }
  }

  const gtmEvent = {
    event: "imAnalyticsEvent",
    event_data: {
      category: categoryValue,
      action: action,
      label: label
    }
  }

  if (ecommerceValue && (action !== "cta sticky"))
    gtmEvent.ecommerce = ecommerceValue

  window.dataLayer.push(gtmEvent);
};

export const GTMEventVirtualPage = () => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    'event': 'virtual_page',
    'pageType': 'product_offer_choice'
  });
};