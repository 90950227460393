import * as React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import MuiButton from "@material-ui/core/Button"

import Icon from "./Icon"
import Text from "./Text"

import { GTMEvent } from "../utils/gtm"

export const styles = theme => ({
  /* Styles applied to the root element. */
  /*root: {
    },*/
  arrow: {
    marginLeft: 12,
    width: 32,
    height: 32,
  },
  highlightedText: {
    paddingLeft: theme.spacing(1),
  },
})

const Button = React.forwardRef(function Button(props, ref) {
  const {
    classes,
    className,
    variant,
    next,
    highlightedText,
    children,
    endIcon,
    ...others
  } = props

  return (
    <MuiButton
      onClick={() => {
        if (
          others.trackingObj ||
          others.trackingAction ||
          others.trackingLabel
        ) {
          GTMEvent(
            others.trackingObj || null,
            others.trackingAction || others.trackingObj?.seo?.title || "",
            others.trackingLabel || ""
          )
        }
      }}
      className={clsx(
        //classes.root,
        className
      )}
      ref={ref}
      {...others}
    >
      {children}
      {highlightedText && (
        <Text
          className={classes.highlightedText}
          weight="bold"
          variant="titleXL"
          component="span"
        >
          {highlightedText}
        </Text>
      )}
      {next && <Icon className={classes.arrow} name="right_white"></Icon>}
      {endIcon && <Icon className={classes.arrow} name={endIcon}></Icon>}
    </MuiButton>
  )
})

Button.propTypes = {
  /**
   * the special text
   */
  highlightedText: PropTypes.string,
  /**
   * Show the next arrow.
   */
  next: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
}

export default withStyles(styles, { name: "FHButton" })(Button)
