import React from 'react';

export default ({ error, parentModule }) => {
    return (

        <div className="deaError" style={{ border: "5px solid red", textAlign: "center" }}  >
            <h3 className="deaErrorMessage"  >{`ERRORE: ${error}`}</h3>
            <pre className="deaErrorMessage"  >{`ERRORE: ${error.stack}`}</pre>
            <div className="deaModuleDetail" >
                <textarea style={{  textAlign: "left", width: "100%" }}
                    defaultValue={JSON.stringify(parentModule, null, 4)} />
                </div>
        </div>


    );
}
