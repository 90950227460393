import * as React from "react"
import { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { showGuides } from "@src/utils/debug"
import { webpSort } from "@src/utils/webp"
import shortid from "shortid"

export const styles = theme => ({
  /* Styles applied to the root element. */
  "@keyframes slideIn15": {
    "0%": { opacity: 0, transform: "translateY(15%)" },
    "100%": { opacity: 1, transform: "translateY(0%)" },
  },
  "@keyframes slideIn50": {
    "0%": { opacity: 0, transform: "translateY(50%)" },
    "100%": { opacity: 1, transform: "translateY(0%)" },
  },
  "@keyframes slideIn100": {
    "0%": { opacity: 0, transform: "translateY(100%)" },
    "100%": { opacity: 1, transform: "translateY(0%)" },
  },
  root: {
    display: "block",
    //visibility: 'hidden',
    opacity: "0",
    textAlign: "center",
    border: showGuides("3px solid green"), // FIX ** guides
  },
  fluid: {
    width: "auto",
    height: "auto",
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    border: showGuides("2px dashed red"), // FIX ** guides
  },
  loaded: {
    //visibility: 'visible',
    transition: "opacity 200ms ease-out",
    opacity: "1",
  },
  slideInImage15: {
    animation: "$slideIn15 1s",
  },
  slideInImage50: {
    animation: "$slideIn50 1s",
  },
  slideInImage100: {
    animation: "$slideIn100 1s",
  },
})

const FluidImage = React.forwardRef(function FluidImage(props, ref) {
  const {
    classes,
    className,
    innerClassName,
    webp,
    src,
    srcset,
    sources,
    alt,
    onLoad,
    animationDepth = 5,
    animationDelay = 0,
    avoidExtraAnimation = false,
  } = props
  const standardDelay = 50
  const standardDuration = 1000
  const imageRef = useRef(null)

  const handleOnLoad = () => {
    if (typeof onLoad !== "undefined" && onLoad !== false) {
      onLoad.apply(null, [imageRef])
    } else {
      if (!avoidExtraAnimation) {
        if (animationDelay) {
          imageRef.current.parentElement.parentElement.animate(
            [
              { opacity: 0, transform: `translateY(${animationDepth}%)` },
              { opacity: 1, transform: "translateY(0%)" },
            ],
            {
              // timing options
              duration: standardDuration,
              delay: animationDelay * standardDelay,
            }
          )
        } else {
          imageRef.current.parentElement.parentElement.classList.add(
            classes[`slideInImage${animationDepth}`]
          )
        }
      }
    }
    let css = `visibility: visible; transition: opacity 200ms ease-out; opacity: 1`
    imageRef.current.parentElement.parentElement.style.cssText = css
  }

  // Fix for cached images not triggering onLoad event
  /*useEffect(() => {
    if (imageRef.current) {
      let t = imageRef.current.classList
      if (t.contains("lazyload") || t.contains("lazyloaded")) return // Lazy will take care of this issue
      if (imageRef.current.complete) {
        handleOnLoad()
      }
    }
  }, [])*/

  return (
    <div className={clsx(classes.root, className)} ref={ref}>
      <picture>
        {/*(sources || []).map(({ srcset, media, type }) => (
                    <source srcSet={srcset} media={media} type={type} />
                ))*/}
        {(webpSort(sources) || []).map(({ srcset, media, type }) => (
          <source
            key={shortid.generate()}
            data-srcset={srcset}
            media={media || "all"}
            type={type}
          />
        ))}
        <img
          onLoad={handleOnLoad}
          ref={imageRef}
          className={clsx(classes.fluid, innerClassName, "lazyload")}
          data-src={src}
          src={src}
          alt={alt}
          title={alt}
        />
      </picture>
    </div>
  )
})

FluidImage.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The webp format
   */
  webp: PropTypes.string,

  /**
   * The source of the image
   */
  src: PropTypes.string,

  /**
   * The alt attribute provides alternative information for an image if a user for some reason cannot view it
   */
  alt: PropTypes.string,

  /**
   * Depth of the animation to use. avoidExtraAnimation must be true
   */
  animationDepth: PropTypes.oneOf([15, 50, 100]),
}

export default withStyles(styles, { name: "FHFluidImage" })(FluidImage)
