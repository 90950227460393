import React, { useRef, useEffect } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Grid,
  Box,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core"
import Slide from "@material-ui/core/Slide"

import Text from "./Text"
import Icon from "./Icon"
import Link from "./Link"
import cookie from "../utils/cookie"
import { detectAlpha2Country } from "../utils/country"

const useStyles = makeStyles(theme => ({
  giftOverlay: {},
  appBar: {
    position: "relative",
    padding: "0 8px 0 8px !important",
    background: "white",
    borderBottom: `1px solid ${theme.customPalette.greyClear}`,
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  appBarSticky: {
    top: 0,
    left: "auto",
    right: 0,
    width: "100%",
    zIndex: 1100,
    position: "sticky",
    padding: "0 8px 0 8px !important",
    background: "white",
    borderBottom: `1px solid ${theme.customPalette.greyClear}`,
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  choose: {
    border: "1px solid #BCBCBC",
    borderRadius: 3,
    padding: "20px 20px 10px 20px",
  },
  country: {
    maxWidth: "100%",
    width: 220,
    padding: "10px 15px",
    marginBottom: 10,
    textDecoration: "none",
    borderRadius: 6,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& .flagTextContainer": {
      display: "flex",
      justifyContent: "center",
    },

    "& .countryFlag": {
      marginRight: 15,
      "& .flag": {
        height: 25,
        width: 35,
        border: "1px solid #B4BAC0",
        overflow: "hidden",
        borderRadius: 3,
        "& img": {
          boxShadow: "0px 0px 14px 0px rgba(102, 102, 102, 0.37)",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        },
      },
    },

    "& .countryText": {
      paddingRight: 15,
      display: "flex",
      "& .text": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: 12,
        fontweight: 600,
        color: theme.customPalette.darkGrey,
      },
    },

    "& .countryArrow": {
      "& .arrow": {
        display: "none",
        width: 22,
        height: "auto",
      },
    },

    "&:hover, &.active": {
      backgroundColor: "#D5CCDC",
      color: "#5D2B7D",
    },

    "&:hover .arrow, &.active .arrow": {
      display: "block !important",
      margin: "0 0 auto auto",
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const ChooseCountryOverlay = ({ className, open, onClose, countries }) => {
  const classes = useStyles()
  const cookieNameOfSelectedCountry = "cookie_country"

  const setAbsoluteLink = url => {
    if (url?.includes("http")) {
      return url
    }
    // TODO gestire baseDomain per l'ambiente di sviluppo
    const baseDomain = process.env.GATSBY_BASE_DOMAIN || ""
    // correzione uk
    url = url?.toLowerCase() === "gb" ? "uk" : url
    url = url?.substr(0, 1) != "/" ? "/" + url : url
    url = url?.substr(-1) != "/" ? url + "/" : url
    return baseDomain + url?.toLowerCase()
  }

  return (
    <Dialog
      className={clsx(className, classes.giftOverlay)}
      fullWidth
      keepMounted
      maxWidth="md"
      PaperProps={{
        square: true,
      }}
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar disableGutters>
          <Text variant="title" color="textPrimary" className={classes.title}>
            Choose your country
          </Text>
          <IconButton
            edge="start"
            size="small"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Icon className={classes.icon} name="close" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.body}>
        <div className={classes.choose}>
          {countries &&
            countries.length > 0 &&
            countries
              .sort(function (a) {
                if (a.code.toLowerCase() === detectAlpha2Country()) return -1
                return 0
              })
              .map((country, index) => {
                return (
                  <Link
                    key={index}
                    className={`
                  ${classes.country}
                  ${
                    country.code.toLowerCase() === detectAlpha2Country()
                      ? "active"
                      : ""
                  }
                `}
                    onClick={e => {
                      e.preventDefault()
                      // set cookie for redirect
                      cookie.setRealCookie(
                        cookieNameOfSelectedCountry,
                        JSON.stringify({
                          countryCode:
                            country.code === "GB" ? "UK" : country.code,
                          countryName: country.name,
                        }),
                        30
                      )
                      window.location.href = setAbsoluteLink(country.code)
                    }}
                    href={setAbsoluteLink(country.code)}
                    hrefLang={country.language}
                    rel="nofollow"
                    target="_self"
                  >
                    <div className="flagTextContainer">
                      <div className="countryFlag">
                        <div class="flag">
                          <img src={country.flag} alt={country.name} />
                        </div>
                      </div>
                      <div className="countryText">
                        <Text
                          defaultSize="12"
                          tag="p"
                          font="Poppins"
                          className="text"
                        >
                          {country.name}
                        </Text>
                      </div>
                    </div>
                    <div className="countryArrow">
                      <Icon className="arrow" name="right_purple" />
                    </div>
                  </Link>
                )
              })}
        </div>
      </Box>
    </Dialog>
  )
}

export default ChooseCountryOverlay
